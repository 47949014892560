@import "bootstrap-icons/font/bootstrap-icons";
/* .is-active {
    font-weight: 600;
    color: rgb(238, 167, 167) !important;
  } */

  
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation: mymove 3s ;
  }
  
/*FADE IN LEFT*/

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft{
  
  0% {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
/*FADE IN RIGHT*/

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}


@keyframes fadeInRight{
  
  0% {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
/*FADE IN DOWN*/


.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInDown{
  
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
/*FADE IN UP*/


.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}


@keyframes fadeInUp{
  
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
/*ZOOM IN*/

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@keyframes zoomIn{
0% {
  opacity: 0;
  -webkit-transform: scale3d(.3, .3, .3);
  transform: scale3d(.3, .3, .3);
}
50% {
  opacity: 1;
}
}


.btn-info-gradiant {
  background-image: linear-gradient(to right,#0084ff,#5baefc);
  border: 0px;
}

.btn-info-gradiant:hover {
 background-image: linear-gradient(to right, #0084ff,#a5d0f8);
}

.btn-info-gradiant.active,
.btn-info-gradiant:active,
.btn-info-gradiant:focus {
  box-shadow: 0px;
  opacity: 1;
}